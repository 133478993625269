import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart  from 'react-apexcharts';

// import constants
import { LOADING_STATUS } from '../../../constants/loadingStatuses';

// import utils
import { _t }      from '../../../utils/i18n';
import { getUser } from '../../../utils/Auth';

// import components
import ProductsPerformingTitle         from './ProductsPerformingTitle';
import ProductsPerformingProduct       from './ProductsPerformingProduct';
import ProductsPerformingSkeletonChart from './ProductsPerformingSkeletonChart';
import ProductsPerformingSkeletonList  from './ProductsPerformingSkeletonList';

// import config
import { donutConfig } from './config';

const ProductsPerforming = memo( () => {
  // get global state
  const { status, productsPerformingData, isRemembered} = useSelector( state => {
    const {
      landing: { status, productsPerformingData },
      login: { isRemembered }
    } = state;

    return { status, productsPerformingData, isRemembered };
  });

  // get the user currency
  const { currency } = getUser( isRemembered );

  // define the chart options
  const options = donutConfig( productsPerformingData );
  const { series, chartDimensions } = options;

  // define skeleton
  const skeleton = Array.from({ length: 5 }, ( _, index ) => {
    return <ProductsPerformingSkeletonList key={ index } />;
  });

  return (
    <div className="products-performing">
      <div className="products-performing__top">
        <ProductsPerformingTitle title={ _t( 'product_performing_col_1' ) } extraClass="products-performing__column--chart" />
        <ProductsPerformingTitle title={ _t( 'product_performing_col_2' ) } extraClass="products-performing__column--list" />
        <ProductsPerformingTitle title={ `${ _t( 'product_performing_col_3' ) } ${ currency }` } extraClass="products-performing__column--percentage" />
      </div>

      <div className="products-performing__content">
        { status === LOADING_STATUS ?
          <ProductsPerformingSkeletonChart />
        : <div className="products-performing__donut">
            <ReactApexChart options={ options }
                          series={ series }
                          type="donut"
                          width={ chartDimensions }
                          height={ chartDimensions } />
          </div>
        }

        <div className="products-performing__products-list">
          { status === LOADING_STATUS ?
            skeleton
          : productsPerformingData.map( ( item, index ) => (
            <ProductsPerformingProduct key={ item.id }
                                       data={ item }
                                       color={ options.colors[index] }/>
            ))
          }
        </div>
      </div>
    </div>
  )
});

export default ProductsPerforming;