import React           from 'react';
import { useDispatch } from 'react-redux';

// import utils
import { _t } from '../../../utils/i18n';

// import helpers
import { getPaymentMethodName }                                from '../../../helpers/getPaymentMethod';
import { tableHeaderNameByKeyword, tablePaymentTypeByKeyword } from './table-helper';

// import actions
import { showToast } from '../Toast/store/toastSlice';

const TablePrint = ( { data } ) => {
  const printStyles = '/print.css';
  const dispatch    = useDispatch();

  /**
   * A function to show a print modal window with the table data
   *
   * @param data | array
   */
  const handlePrint = data => {
    if ( data.length > 0 ) {
      // exclude and replace unnecessary fields
      const dataWithExcludedFields = data.map( item => {
        const { name } = getPaymentMethodName( item.payment_parameter );

        let updatedItem = {
          ...item,
          interval: _t( 'table_interval_' + item.interval ),
          payment_parameter: name,
          donation_purpose: item.donation_purpose === 'no_purpose' ? _t( 'table_no_purpose' ) : item.donation_purpose,
          donation_type: tablePaymentTypeByKeyword( item.donation_type ),
        };

        // add translatable text to the status column
        if ( item.active !== undefined ) {
          updatedItem = {
            ...updatedItem,
            active: item.active ? _t( 'table_active' ) : _t( 'table_inactive' ),
          }
        }

        const { payment_method, resend_invoice_type, identifier, ...newItem } = updatedItem;

        return newItem;
      });

      // get headers from data
      const tableHeader = Object.keys( dataWithExcludedFields[0] ).map( value => {
        return '<th class="print-table__head__cell">' + tableHeaderNameByKeyword( value ) + '</th>';
      });

      // get rows from data
      const tableRowsData = dataWithExcludedFields.map( item => {
        return Object.values( item ).map( value => {
          return '<td class="print-table__body__cell">' + value + '</td>';
        });
      });

      // prepare rows
      const tableRows = tableRowsData.map( row => {
        return '<tr class="print-table__body__row">' + row.join( '' ) + '</tr>';
      });

      // create the table markup
      const table = '<table class="print-table">' +
        ' <thead class="print-table__head"><tr>' + tableHeader.join( '' ) + '</tr></thead>' +
        ' <tbody class="print-table__body">' + tableRows.join( '' ) + '</tbody>' +
        '</table>';

      const printIframe    = window.frames['print-table'];
      const printIframeDoc = printIframe.document;
      const printIframeWnd = printIframe.window;

      // write out the prepared table with the data to an iframe
      printIframeDoc.write( '<html><head><link href="' + printStyles + '" rel="stylesheet" type="text/css" media="print"></head></html>' );
      printIframeDoc.body.innerHTML = table;

      // apply styles
      setTimeout(() => {
        printIframeWnd.focus();
        printIframeWnd.print();
      }, 100 );
    } else {
      dispatch( showToast( { isShown: true, type: 'warning', text: _t( 'table_no_data' ) } ) );
    }
  };

  return (
    <button className="table__action-btn"
            type="button"
            onClick={ () => handlePrint( data ) }>
      { _t( 'table_btn_print' ) }
    </button>
  )
};

export default TablePrint;