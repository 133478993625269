import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client }                        from '../../../api/client';

// import constants
import { API_URL } from '../../../constants/base';
import { IDLE_STATUS, LOADING_STATUS, SUCCEEDED_STATUS, FAILED_STATUS } from '../../../constants/loadingStatuses';
import {
  ALLPAYMENTS_FETCH, ALLPAYMENTS_DATE_UPDATE, ALLPAYMENTS_GET_ALLTIME, TOAST_SHOW
} from '../../../constants/dispatchTypes';

// import helpers
import { refreshTokenHelper, initialRequest, updateDateRequest } from '../../../api/helper';
import { tablePaymentTypeByKeyword } from '../../../components/elements/Table/table-helper';

// import utils
import { _t, setMetaTitle } from '../../../utils/i18n';
import { isUserRemembered, getAccessToken } from '../../../utils/Auth';

// import config
import { sortByDate, sortByAmount } from '../../../components/elements/Table/config';

// import components
import TableActions       from '../../../components/elements/Table/TableActions';
import TableNameAddress   from '../../../components/elements/Table/TableNameAddress';
import TablePaymentMethod from '../../../components/elements/Table/TablePaymentMethod';

// define API urls
const defaultUrl = 'dashboard_stats_api/all-payments/';
const updateUrl  = 'dashboard_stats_api/update-all-payments/';

// define table options
const tableOptions = [
  {
    id: 'actions',
    name: _t( 'table_actions' ),
    selector: row => <TableActions row={ row } />,
    width: '65px',
  },
  {
    id: 'order-nr',
    name: _t( 'table_order_num' ),
    selector: row => row.order_nr,
    sortable: true,
    width: '100px',
  },
  {
    id: 'date',
    name: _t( 'table_date' ),
    selector: row => row.date,
    sortable: true,
    width: '80px',
    sortFunction: sortByDate,
  },
  {
    id: 'name',
    name: _t( 'table_name' ),
    selector: row => <TableNameAddress address={ row.full_address } />,
  },
  {
    id: 'amount',
    name: _t( 'table_amount' ),
    selector: row => row.amount,
    sortable: true,
    sortFunction: sortByAmount,
  },
  {
    id: 'payment-type',
    name: _t( 'table_payment_type' ),
    selector: row => <span title={ tablePaymentTypeByKeyword( row.donation_type ) }>{ tablePaymentTypeByKeyword( row.donation_type ) }</span>,
  },
  {
    id: 'product',
    name: _t( 'table_product' ),
    selector: row => <span title={ row.product }>{ row.product }</span>,
  },
  {
    id: 'donation-purpose',
    name: _t( 'table_donation_purpose' ),
    selector: row => row.donation_purpose === 'no_purpose' ? _t( 'table_no_purpose' ) : row.donation_purpose,
  },
  {
    id: 'payment-method',
    name: _t( 'table_payment_method' ),
    selector: row => <TablePaymentMethod data={ row } />,
    width: '160px',
  }
];

/**
 * Fetching initial data request
 *
 * @return object
 */
export const fetchAllPayments = createAsyncThunk(
  ALLPAYMENTS_FETCH,
  async ( args, thunkAPI ) =>
    initialRequest( args, thunkAPI, { defaultUrl, updateUrl }, false )
);

/**
 * Make request to update the data if the date range was changed
 *
 * @param dateRange | object
 * @param updatingData | object
 * @return function
 */
const updatingData = {
  defaultUrl,
  updateUrl,
  dispatchType: ALLPAYMENTS_DATE_UPDATE
};

export const updatingAllPayments = dateRange => updateDateRequest( dateRange, updatingData, false );

/**
 * Make request to update the table with the All time transactions data for the All Payments
 */
export const getAllTimeForAllPayments = () => {
  return async function getAllTimeThunk( dispatch ) {
    try {
      const isRemembered = isUserRemembered();

      await refreshTokenHelper( 'update', isRemembered, dispatch );

      const response = await client.get(
        API_URL + 'dashboard_stats_api/get-all-payments-ever/',
        { headers: { 'Authorization': "Bearer " + getAccessToken( isRemembered ) } }
      );

      dispatch( { type: ALLPAYMENTS_GET_ALLTIME, payload: response.data } );
    } catch ( err ) {
      dispatch( { type: TOAST_SHOW, payload: { isShown: true, type: 'danger', text: _t( 'fetch_error' ) } } );
      console.log( err );

      return Promise.reject( err );
    }
  }
};

const allPaymentsSlice = createSlice({
  name: 'allPayments',
  initialState: {
    status: IDLE_STATUS,
    tableStatus: IDLE_STATUS,
    metaTitle: '',
    topStatsData: [],
    topProductsChartData: [],
    tableData: [],
    tableOptions,
    error: _t( 'fetch_error' )
  },
  reducers: {
    statusUpdated: ( state, action ) => {
      const { payload } = action;

      state.status      = payload;
      state.tableStatus = payload;
    },
    tableStatusUpdated: ( state, action ) => {
      state.tableStatus = action.payload;
    },
    dateUpdated: ( state, action ) => {
      const { topStatsData, topProductsChartData, tableData } = action.payload;

      state.topStatsData         = topStatsData !== undefined ? topStatsData : state.topStatsData;
      state.topProductsChartData = topProductsChartData !== undefined ? topProductsChartData : state.topProductsChartData;
      state.tableData            = tableData !== undefined ? tableData : state.tableData;
      state.status               = SUCCEEDED_STATUS;
      state.tableStatus          = SUCCEEDED_STATUS;
    },
    getAllTime: ( state, action ) => {
      const { tableData } = action.payload;

      state.tableData   = tableData !== undefined ? tableData : state.tableData;
      state.tableStatus = SUCCEEDED_STATUS;
    },
  },
  extraReducers( builder ) {
    builder
      .addCase( fetchAllPayments.pending, state => {
        state.status      = LOADING_STATUS;
        state.tableStatus = LOADING_STATUS;
      })
      .addCase( fetchAllPayments.fulfilled, ( state, action ) => {
        const { topStatsData, topProductsChartData, tableData, metas } = action.payload;

        state.metaTitle            = metas !== undefined ? setMetaTitle( metas ) : state.metaTitle;
        state.topStatsData         = topStatsData !== undefined ? topStatsData : state.topStatsData;
        state.topProductsChartData = topProductsChartData !== undefined ? topProductsChartData : state.topProductsChartData;
        state.tableData            = tableData !== undefined ? tableData : state.tableData;
        state.status               = SUCCEEDED_STATUS;
        state.tableStatus          = SUCCEEDED_STATUS;
      })
      .addCase( fetchAllPayments.rejected, ( state, action ) => {
        state.status      = FAILED_STATUS;
        state.tableStatus = FAILED_STATUS;
        state.error       = action.error.message;

        console.log( action.error.message );
      })
  },
});

export const {
  statusUpdated: allPaymentsStatusUpdating,
  tableStatusUpdated: allPaymentsTableStatusUpdating
} = allPaymentsSlice.actions;

export default allPaymentsSlice.reducer;