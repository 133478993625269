import React                            from 'react';
import { /*useDispatch,*/ useSelector } from 'react-redux';
// import { NavLink } from 'react-router-dom';

// import actions
//import { changeSection }    from '../../common/Sidebar/store/sidebarSlice';

// import utils
import { _t }      from '../../../utils/i18n';
import { getUser } from '../../../utils/Auth';

// import components
import SidebarNavLogout from './SidebarNavLogout';

export default function SidebarNavBottom() {
  const { isCollapsed, isRemembered } = useSelector( state => {
    const {
      sidebar: { isCollapsed },
      login: { isRemembered }
    } = state;

    return { isCollapsed, isRemembered };
  });

  // define dispatch
  // const dispatch = useDispatch();

  // get username
  const { userName, company } = getUser( isRemembered );

  /**
   * Processing click on the Support and User profile link
   */
  /*const handleClick = () => {
    const data = {
      activeSection: 'support',
      sectionTitle: ''
    };

    dispatch( changeSection( data ) );
  };*/

  return (
    <div className={ isCollapsed ? 'sidebar-nav__bottom sidebar-nav__bottom--is-collapsed' : 'sidebar-nav__bottom' }>
      <span className="sidebar-nav__item sidebar-nav__item--bottom sidebar-nav__item--is-disabled"
            title={ _t( 'menu_disabled_msg' ) }>
        { _t( 'menu_title_support' ) }
      </span>

      <div className="sidebar-nav__item sidebar-nav__item--bottom sidebar-nav__item--is-disabled sidebar-nav__item--is-user">
        <div className="sidebar-nav__item__user"
             title={ _t( 'menu_disabled_msg' ) }>
          <span className="sidebar-nav__item__user__name">{ userName }</span>
          <span className="sidebar-nav__item__user__company">{ company }</span>
        </div>
        <SidebarNavLogout />
      </div>
    </div>
  );
}