// import helpers
import { swissNumberInConfig } from '../../../helpers/swissNumberFormat';

// import utils
import { _t } from '../../../utils/i18n';

/**
 * ApexChart options for the bar chart type
 *
 * @param data | object
 * @param isPaymentMethodsBar | bool
 * @return object
 */
export function barConfig( data, isPaymentMethodsBar ) {
  const defaultColor = '#7E3BC6';
  const colors = isPaymentMethodsBar ? [...data.map( item => item.color !== undefined ? item.color : defaultColor )] : [defaultColor];

  const borderColor = '#DADEE5';
  const fontFamily  = 'Montserrat, sans-serif';
  const fontSize    = '12px';
  const axisStyles  = {
    colors: '#536074',
    fontSize: fontSize,
    fontFamily: fontFamily,
    fontWeight: 400,
  };

  const axisBorder = {
    show: true,
    color: borderColor,
  };

  return {
    series: [{
      name: _t( 'all_payments_top_products_name' ),
      data: [...data.map( item => { return { x: item.x, y: item.y } } )]
    }],
    chart: {
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 500,
      },
      toolbar: {
        show: false,
      },
    },
    colors,
    dataLabels: {
      formatter: value => swissNumberInConfig( value, false ),
      offsetY: 15,
      style: {
        fontSize: '14px',
        fontFamily: fontFamily,
        fontWeight: '700',
        colors: ['#fff']
      },
    },
    grid: {
      borderColor: borderColor,
      xaxis: {
        lines: {
          show: false
        }
      },
    },
    legend: {
      show: false
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: 'top',
        }
      }
    },
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.01,
        }
      }
    },
    tooltip: {
      style: {
        fontSize: fontSize,
        fontFamily: fontFamily
      },
      y: {
        formatter: value => swissNumberInConfig( value, true ),
      }
    },
    xaxis: {
      axisBorder,
      labels: {
        style: axisStyles,
        rotate: 0,
        hideOverlappingLabels: false,
        trim: true,
      }
    },
    yaxis: {
      axisBorder,
      labels: {
        formatter: value => swissNumberInConfig( value, false ),
        style: axisStyles,
      },
    },
  }
}