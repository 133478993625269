import React from 'react';

// import utils
import { _t } from '../../../utils/i18n';

export default function UnderConstruction() {
  return (
    <div className="main__no-support">
      <h2 className="main__no-support__text">{ _t( 'no_responsive_msg' ) }</h2>
    </div>
  );
}