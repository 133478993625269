// import constants
import { ACCESS_STORAGE_PREFIX } from '../../constants/base';

export const isBrowser = () => typeof window !== 'undefined';

/**
 * Get information about the user
 *
 * @param session | bool
 * @return object
 */
export const getUser = session => {
  if ( session ) {
    return (
      isBrowser() && window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'User' )
        ? JSON.parse( window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'User' ) )
        : {}
    )
  } else {
    return (
      isBrowser() && window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'User' )
        ? JSON.parse( window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'User' ) )
        : {}
    )
  }
};

/**
 * Get information about the user
 *
 * @param user | object
 * @param session | bool
 */
export const setUser = ( user, session ) => {
  if ( session ) {
    window.localStorage.setItem( ACCESS_STORAGE_PREFIX + 'User', JSON.stringify( user ) );
  } else {
    window.sessionStorage.setItem( ACCESS_STORAGE_PREFIX + 'User', JSON.stringify( user ) );
  }
};

/**
 * Save access token
 *
 * @param token | string
 * @param session | bool
 */
export const setAccessToken = ( token, session ) => {
  if ( session ) {
    window.localStorage.setItem( ACCESS_STORAGE_PREFIX + 'AccessToken', JSON.stringify( token ) );
  } else {
    window.sessionStorage.setItem( ACCESS_STORAGE_PREFIX +'AccessToken', JSON.stringify( token ) );
  }
};

/**
 * Save refresh token
 *
 * @param token | string
 * @param session | bool
 */
export const setRefreshToken = ( token, session ) => {
  if ( session ) {
    window.localStorage.setItem( ACCESS_STORAGE_PREFIX + 'RefreshToken', JSON.stringify( token ) );
  } else {
    window.sessionStorage.setItem( ACCESS_STORAGE_PREFIX +'RefreshToken', JSON.stringify( token ) );
  }
};

/**
 * Get access token
 *
 * @param session | bool
 * @return string
 */
export const getAccessToken = session => {
  if ( session ) {
    return (
      isBrowser() && window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' )
        ? JSON.parse( window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' ) )
        : ''
    );
  } else {
    return (
      isBrowser() && window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' )
        ? JSON.parse( window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' ) )
        : ''
    );
  }
};

/**
 * Get refresh token
 *
 * @param session | bool
 * @return string
 */
export const getRefreshToken = session => {
  if (
    ( window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' ) &&
      window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' ) !== 'undefined' ) ||
    ( window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' ) &&
      window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' )!== 'undefined' ) ||
    ( window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' ) &&
      window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' ) !== 'undefined' ) ||
    ( window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' ) &&
      window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' )!== 'undefined' )
  ) {
    if ( session ) {
      return (
        isBrowser() && window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' )
          ? JSON.parse( window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' ) )
          : ''
      );
    } else {
      return (
        isBrowser() && window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' )
          ? JSON.parse( window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' ) )
          : ''
      );
    }
  } else {
    logout();
  }


};

/**
 * Check if user is logged in
 *
 * @param session | bool
 * @return boolean
 */
export const isLoggedIn = session => {
  const user = getUser( session );

  return !!user.userName;
};

/**
 * Check if user already logged in
 *
 * @return boolean
 */
export const isUserLoggedIn = () => {
  if (
    ( window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' ) &&
      window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' ) !== 'undefined' ) ||
    ( window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' ) &&
      window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' )!== 'undefined' ) ||
    ( window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' ) &&
      window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' ) !== 'undefined' ) ||
    ( window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' ) &&
      window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' )!== 'undefined' )
  ) {
    return true;
  } else {
    logout();

    return false
  }
};

/**
 * Check if user remembered
 *
 * @return boolean
 */
export const isUserRemembered = () => {
  if ( window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'User' ) ) {
    return true;
  } else return false;
};


/**
 * Remove User and Access token after logout
 */
export const logout = () => {
  // remove user data from storage
  if (
    window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'User' ) ||
    window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'User' )
  ) {
    window.sessionStorage.removeItem( ACCESS_STORAGE_PREFIX + 'User' );
    window.localStorage.removeItem( ACCESS_STORAGE_PREFIX + 'User' );
  }

  // remove access token from storage
  if (
    window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' ) ||
    window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'AccessToken' )
  ) {
    window.sessionStorage.removeItem( ACCESS_STORAGE_PREFIX + 'AccessToken' );
    window.localStorage.removeItem( ACCESS_STORAGE_PREFIX + 'AccessToken' );
  }

  // remove refresh token from storage
  if (
    window.sessionStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' ) ||
    window.localStorage.getItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' )
  ) {
    window.sessionStorage.removeItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' );
    window.localStorage.removeItem( ACCESS_STORAGE_PREFIX + 'RefreshToken' );
  }
};