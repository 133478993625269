// import constants
import {
  CALENDAR_LAST_7_DAYS, CALENDAR_LAST_MONTH, CALENDAR_LAST_QUARTER, CALENDAR_LAST_YEAR, CALENDAR_THIS_MONTH,
  CALENDAR_THIS_QUARTER, CALENDAR_THIS_YEAR, CALENDAR_TODAY, CALENDAR_YESTERDAY
} from '../../../constants/calendarRanges';

// import utils
import { _t } from '../../../utils/i18n';

/**
 * Table description text based on the current date range
 *
 * @param period | string
 * @return string
 */
export function tableDescription( period ) {
  const tableFromText = _t( 'table_from' );

  switch( period ) {
    case CALENDAR_TODAY:
      return `${ tableFromText } ${ _t( 'table_today' ) }`;
    case CALENDAR_YESTERDAY:
      return `${ tableFromText } ${ _t( 'table_yesterday' ) }`;
    case CALENDAR_LAST_7_DAYS:
      return `${ tableFromText } ${ _t( 'table_7_days' ) }`;
    case CALENDAR_THIS_MONTH:
      return `${ tableFromText } ${ _t( 'table_this_month' ) }`;
    case CALENDAR_LAST_MONTH:
      return `${ tableFromText } ${ _t( 'table_last_month' ) }`;
    case CALENDAR_THIS_QUARTER:
      return `${ tableFromText } ${ _t( 'table_this_quarter' ) }`;
    case CALENDAR_LAST_QUARTER:
      return `${ tableFromText } ${ _t( 'table_last_quarter' ) }`;
    case CALENDAR_THIS_YEAR:
      return `${ tableFromText } ${ _t( 'table_this_year' ) }`;
    case CALENDAR_LAST_YEAR:
      return `${ tableFromText } ${ _t( 'table_last_year' ) }`;
    default:
      return `${ tableFromText } ${ _t( 'table_last_30_days' ) }`;
  }
}

/**
 * Convert the table keywords to the translatable text and use it in the table header
 *
 * @param keyword | string
 * @return string
 */
export const tableHeaderNameByKeyword = keyword => {
  switch ( keyword ) {
    case 'date':
      return _t( 'table_date' );
    case 'full_address':
      return _t( 'table_name' );
    case 'amount':
      return _t( 'table_amount' );
    case 'totalAmount':
      return _t( 'table_amount_total' );
    case 'donation_type':
      return _t( 'table_payment_type' );
    case 'product':
      return _t( 'table_product' );
    case 'donation_purpose':
      return _t( 'table_donation_purpose' );
    case 'payment_parameter':
      return _t( 'table_payment_method' );
    case 'payment_method':
      return _t( 'table_payment_method' );
    case 'active':
      return _t( 'table_status' );
    case 'interval':
      return _t( 'table_interval' );
    case 'next_payment_date':
      return _t( 'table_next_payment' );
    default:
      return _t( 'table_order_num' );
  }
};

/**
 * Convert the table payment type keywords to the translatable text and use it in the table columns
 *
 * @param keyword | string
 * @return string
 */
export const tablePaymentTypeByKeyword = keyword => {
  switch ( keyword ) {
    case 'recurring-donation':
      return _t( 'table_payment_type_recurring_donation' );
    case 'recurring-donation_init_web':
      return _t( 'table_payment_type_recurring_donation_init_web' );
    case 'recurring-donation_init_invoice':
      return _t( 'table_payment_type_recurring_donation_init_invoice' );
    case 'recurring-donation_interval_web':
      return _t( 'table_payment_type_recurring_donation_interval_web' );
    case 'recurring-donation_interval_invoice':
      return _t( 'table_payment_type_recurring_donation_interval_invoice' );
    case 'crowdfunding':
      return _t( 'table_payment_type_crowdfunding' );
    case 'iframe-donation':
      return _t( 'table_payment_type_iframe_donation' );
    case 'iframe-donation_init_web':
      return _t( 'table_payment_type_iframe_donation_init_web' );
    case 'iframe-donation_interval_web':
      return _t( 'table_payment_type_iframe_donation_interval_web' );
    case 'iframe-donation_init_invoice':
      return _t( 'table_payment_type_iframe_donation_init_invoice' );
    case 'iframe-donation_interval_invoice':
      return _t( 'table_payment_type_iframe_donation_interval_invoice' );
    default:
      return _t( 'table_payment_type_one_off_donation' );
  }
};