import React                            from 'react';
import { useSelector/*, useDispatch*/ } from 'react-redux';
//import { NavLink }                  from 'react-router-dom';

// import actions
//import { changeSection } from '../../common/Sidebar/store/sidebarSlice';

// import utils
import { _t } from '../../../utils/i18n';

// import icons
import { IconInfoCircle } from '@tabler/icons-react';

export default function SidebarToolsItemSupport() {
  // get the global state
  const { theme, isCollapsed } = useSelector( state => state.sidebar );

  // define dispatch
  //const dispatch = useDispatch();

  /**
   * Processing click
   */
  /*const handleClick = () => {
    const data = {
      activeSection: 'support',
      sectionTitle: ''
    };

    dispatch( changeSection( data ) );
  };*/

  return (
    <span className="sidebar-tools__item sidebar-tools__item--is-disabled"
          title={ _t( 'menu_disabled_msg' ) }>
      <IconInfoCircle color={ theme === 'dark' ? '#fff' : '#444441' } />
      { isCollapsed ?
        <span className="sidebar-tools__item__tooltip">{ _t( 'menu_title_support' ) }</span>
      : false }
    </span>
  )
}