import React, { useEffect, useState } from 'react';
import { Helmet }                     from 'react-helmet';
import { useLocation }                from 'react-router-dom';

// import utils
import { getUser, isUserRemembered } from '../utils/Auth';

// import helpers
import { getWindowDimensions } from '../helpers/getWindowDimensions';

// import components
import Sidebar           from './common/Sidebar/Sidebar';
import Main              from './common/Main/Main';
import UnderConstruction from './common/Main/UnderConstruction';

// import styles
import '../assets/styles/styles.scss';

export default function App() {
  // define the component state
  const [windowSize, setWindowSize] = useState( getWindowDimensions() );

  const { pathname } = useLocation();
  const { language } = getUser( isUserRemembered() );

  // show the page from top after navigation
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [pathname] );

  // get the windows dimensions if the window was resized
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize( getWindowDimensions() );
    }

    window.addEventListener( 'resize', handleWindowResize );

    return () => {
      window.removeEventListener( 'resize', handleWindowResize );
    };
  }, [] );

  return (
    <main className="app-container">
      <Helmet htmlAttributes={{ lang : language }} />
      { windowSize.innerWidth > 1199 ?
        <>
          <Sidebar />
          <Main />
        </>
      :
        <UnderConstruction />
      }
    </main>
  );
}
