import moment from 'moment';

// import helpers
import { tablePaymentTypeByKeyword } from './table-helper';

// import utils
import { _t } from '../../../utils/i18n';

// import components
import TableActions       from './TableActions';
import TableNameAddress   from './TableNameAddress';
import TablePaymentMethod from './TablePaymentMethod';

/**
 * Custom sort function to sort by date
 *
 * @param rowA | object
 * @param rowB | object
 * @return int
 */
export const sortByDate = ( rowA, rowB ) => {
  const a = moment( rowA.date, 'DD-MM-YYYY' );
  const b = moment( rowB.date, 'DD-MM-YYYY' );

  return a._d - b._d;
};

/**
 * Custom sort function to sort by amount
 *
 * @param rowA | object
 * @param rowB | object
 * @return int
 */
export const sortByAmount = ( rowA, rowB ) => {
  const a = rowA.amount.split( ' ' );
  const b = rowB.amount.split( ' ' );
  const amountA = parseFloat( a[1].replace( '\'', '' ) );
  const amountB = parseFloat( b[1].replace( '\'', '' ) );

  if ( amountA > amountB ) { return 1 }
  if ( amountB > amountA ) { return -1 }

  return 0;
};

/**
 * Custom sort function to sort by status
 *
 * @param rowA | object
 * @param rowB | object
 * @return int
 */
export const sortByStatus = ( rowA, rowB ) => {
  const a = rowA.active;
  const b = rowB.active;

  return ( a === b ) ? 0 : a? 1 : -1;
};

/**
 * Custom sort function to sort by next payment
 *
 * @param rowA | object
 * @param rowB | object
 * @return int
 */
export const sortByNextPayment = ( rowA, rowB ) => {
  const a = moment( rowA.next_payment_date, 'DD-MM-YYYY' );
  const b = moment( rowB.next_payment_date, 'DD-MM-YYYY' );

  return a._d - b._d;
};

/**
 * Config for payment methods table
 *
 * @return array
 */
export const paymentMethodsTableConfig = () => {
  return [
    {
      id: 'actions',
      name: _t( 'table_actions' ),
      selector: row => <TableActions row={ row } />,
      width: '65px',
    },
    {
      id: 'order-nr',
      name: _t( 'table_order_num' ),
      selector: row => row.order_nr,
      sortable: true,
      width: '100px',
    },
    {
      id: 'date',
      name: _t( 'table_date' ),
      selector: row => row.date,
      sortable: true,
      width: '80px',
      sortFunction: sortByDate
    },
    {
      id: 'name',
      name: _t( 'table_name' ),
      selector: row => <TableNameAddress address={ row.full_address } />,
    },
    {
      id: 'amount',
      name: _t( 'table_amount' ),
      selector: row => row.amount,
      sortable: true,
      sortFunction: sortByAmount
    },
    {
      id: 'donation-type',
      name: _t( 'table_payment_type' ),
      selector: row => <span title={ tablePaymentTypeByKeyword( row.donation_type ) }>{ tablePaymentTypeByKeyword( row.donation_type ) }</span>,
    },
    {
      id: 'product',
      name: _t( 'table_product' ),
      selector: row => <span title={ row.product }>{ row.product }</span>,
    },
    {
      id: 'donation-purpose',
      name: _t( 'table_donation_purpose' ),
      selector: row => row.donation_purpose === 'no_purpose' ? _t( 'table_no_purpose' ) : row.donation_purpose,
    },
    {
      id: 'payment-method',
      name: _t( 'table_payment_method' ),
      selector: row => <TablePaymentMethod data={ row } />,
      width: '160px',
    }
  ];
};